<template>

  <div class="login">
    <div>
      <sellia-logo class="login__logo" />
    </div>
    <div class="login__container">
      <div class="login-card">
        <div class="login-card__header">
          <h1>{{ $t('login.title') }}</h1>
          <small>{{ $t('login.description') }}</small>
        </div>
        <div class="login-card__content">
          <hcc-input size="fill" name="email" :label="$t('login.email')"
            placeholder="email@sellia.com.mx" requiredInput v-model="email">
          </hcc-input>
          <hcc-input size="fill" name="password " :label="$t('login.password')"
            placeholder="*******" type="password" requiredInput
            v-model="password">
          </hcc-input>
          <div v-if="errors.length > 0" v-html="errors.join('<br>')"
            class="login-card__error"></div>
        </div>
        <div class="login-card__footer">
          <hcc-button size="expanded" :disabled="!valid" @click="login">
            {{ $t('login.accept') }}
          </hcc-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import HccInput from '../components/HccInput.vue'
import HccButton from '../components/HccButton.vue'
import SelliaLogo from '@/assets/images/sellia_black.svg'

export default {
  components: {
    SelliaLogo, HccInput, HccButton
  },
  name: 'Login',
  data: () => ({
    email: '',
    password: ''

  }),
  computed: {
    ...mapState({
      errors: state => state.errors
    }),
    valid() {
      return this.email.length > 0 && this.password.length > 0
    }
  },
  methods: {
    login() {
      this.$store.dispatch('login', {
        email: this.email.trim(),
        password: this.password.trim()
      })
    }
  }
}
</script>

<style lang="scss">
@import "~styles/views/login.scss";
</style>
